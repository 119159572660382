import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  ImageField,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const CarouselGalleryToolbar = (props) => {
  const carouselId =
    props.record && props.record.carouselId ? props.record.carouselId : null;
  const redirectUrl = carouselId
    ? `/carousels/${carouselId}/show/galleries`
    : "/carousels";

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Carousel Gallery" redirect={redirectUrl} />
      <DeleteButton label="Delete" redirect={redirectUrl} />
    </Toolbar>
  );
};

const redirect = (basePath: string, id: any, data: any) => {
  return `/carousels/${data.carouselId}/show/galleries`;
};

const CarouselGalleryEdit = (props: any) => {
  return (
    <div>
      <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<CarouselGalleryToolbar />}>
          <TextInput disabled source="id" />
          <ReferenceInput
            disabled
            label="Carousel"
            source="carouselId"
            reference="carousels"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" />
          <TextInput source="description" />
          <TextInput source="onClickUrl" />
          <TextInput source="onHoverText" />
          <TextInput source="altText" />
          <ImageField label="Current Desktop Image" source="desktopImageUrl" />
          <ImageField label="Current Mobile Image" source="mobileImageUrl" />
          <MediaReferenceInput
            label="Desktop Image from Media"
            source="desktopImageMediaId"
            reference="media"
          />
          <MediaReferenceInput
            label="Mobile Image from Media"
            source="mobileImageMediaId"
            reference="media"
          />
          <NumberInput source="position" />
          <BooleanInput source="active" />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default CarouselGalleryEdit;

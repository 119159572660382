import React from "react";
import {
  Edit,
  SimpleForm,
  BooleanInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  TextInput,
} from "react-admin";

const ProductDescriptionToolbar = (props: any) => {
  const productId =
    props.record && props.record.productId ? props.record.productId : null;
  const redirectUrl = productId
    ? `/products/${productId}/show/descriptions`
    : "/products";

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Product Description" redirect={redirectUrl} />
      <DeleteButton label="Delete" redirect={redirectUrl} />
    </Toolbar>
  );
};

const redirect = (basePath: string, id: string, data: any) => {
  return `/products/${data.productId}/show/descriptions`;
};

const ProductDescriptionEdit = (props: any) => {
  return (
    <div>
      <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<ProductDescriptionToolbar />}>
          <TextInput label="Name" source="name" validate={[required()]} />
          <TextInput
            label="Description"
            source="description"
            validate={[required()]}
          />
          <BooleanInput label="Active" source="active" defaultValue={true} />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default ProductDescriptionEdit;

import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddSubProductButton = ({
  classes,
  record,
}: {
  classes: any;
  record?: any;
}) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/order-shippings/create?orderId=${record.id}`}
    label="Add Order Shippings"
  >
    <AddIcon />
  </Button>
);

export default withStyles(styles)(AddSubProductButton);

import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  CreateProps,
} from "react-admin";

const CarouselCreate = (props: CreateProps) => {
  return (
    <div>
      <Create {...props}>
        <SimpleForm>
          <TextInput source="name" validate={[required()]} />
          <BooleanInput source="mainCarousel" />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default CarouselCreate;

import React from "react";
import {
  Datagrid,
  ReferenceManyField,
  Show,
  EditButton,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  DateField,
  UrlField,
  ImageField,
  TopToolbar,
  ListButton,
  ShowButton,
} from "react-admin";
import AddProductTagButton from "../product-tags/AddProductTagButton";
import DeleteProductTagButton from "../product-tags/DeleteProductTagButton";
import AddProductDescriptionButton from "./AddProductDescriptionButton";
import AddProductGalleryButton from "./AddProductGalleryButton";
import AddSubProductButton from "./AddSubProductButton";

const ProductShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: any;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back To List" />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ProductShow = (props: any) => {
  return (
    <Show {...props} actions={<ProductShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <ReferenceField
            label="Category"
            source="subCategory.categoryId"
            reference="categories"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Sub Category"
            source="subCategoryId"
            reference="subcategories"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="slug" />
          <TextField source="name" />
          <TextField source="displayName" />
          <TextField source="pageTitle" />
          <TextField
            label="English Description (HTML)"
            source="enHtmlDescription"
          />
          <TextField source="status" />
        </Tab>
        <Tab label="Sub Products" path="sub-products">
          <ReferenceManyField
            addLabel={false}
            reference="sub-products"
            target="productId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="slug" />
              <TextField label="SKU" source="sku" />
              <NumberField label="Stock Quantity" source="stockQuantity" />
              <TextField label="Name" source="name" />
              <TextField label="Display Name" source="displayName" />
              <ImageField label="Main Image" source="mainImageUrl" />
              <ImageField label="Thumbnail Image" source="thumbnailImageUrl" />
              <NumberField label="Total Weight (g)" source="totalWeight" />
              <BooleanField label="Main" source="main" />
              <BooleanField label="Active" source="active" />
              <DateField
                label="Created At"
                source="createdAt"
                locales="id-ID"
                showTime
              />
              <DateField
                label="Updated At"
                source="updatedAt"
                locales="id-ID"
                showTime
              />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddSubProductButton />
        </Tab>
        <Tab label="Description" path="descriptions">
          <ReferenceManyField
            addLabel={false}
            reference="product-descriptions"
            target="productId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <TextField label="Name" source="name" />
              <TextField label="Description" source="description" />
              <BooleanField label="Active" source="active" />
              <DateField
                label="Created At"
                source="createdAt"
                locales="id-ID"
                showTime
              />
              <DateField
                label="Updated At"
                source="updatedAt"
                locales="id-ID"
                showTime
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddProductDescriptionButton />
        </Tab>
        <Tab label="Gallery" path="galleries">
          <ReferenceManyField
            addLabel={false}
            reference="product-galleries"
            target="productId"
            sort={{ field: "position", order: "ASC" }}
          >
            <Datagrid>
              <ImageField label="Main Image URL" source="mainImageUrl" />
              <ImageField label="Thumbnail URL" source="thumbnailUrl" />
              <TextField label="Alt Text" source="altText" />
              <NumberField label="Position" source="position" />
              <BooleanField label="Active" source="active" />
              <DateField
                label="Created At"
                source="createdAt"
                locales="id-ID"
                showTime
              />
              <DateField
                label="Updated At"
                source="updatedAt"
                locales="id-ID"
                showTime
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddProductGalleryButton />
        </Tab>
        <Tab label="Tags" path="tags">
          <ReferenceManyField
            addLabel={false}
            reference="product-tags"
            target="productId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <TextField source="name" />
              <BooleanField source="showToUser" />
              <DeleteProductTagButton />
            </Datagrid>
          </ReferenceManyField>
          <AddProductTagButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ProductShow;

import * as React from "react";
import { Create, SimpleForm, TextInput, email, required } from "react-admin";

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" label="First Name" validate={[required()]}/>
      <TextInput source="middleName" label="Middle Name" />
      <TextInput source="lastName" label="Last Name" validate={[required()]} />
      <TextInput  source="email" label="Email" validate={[required(), email()]} />
      <TextInput source="password" type="password" validate={[required()]}/>
      <TextInput source="phoneNumber" label="Phone Number" />
    </SimpleForm>
  </Create>
);

import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  useDataProvider,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

const styles = {
  button: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: "red",
  },
};

const DeleteSubProductVariantButton = ({
  classes,
  record,
}: {
  classes: any;
  record?: any;
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const subProductId =
    record && record.SubProductVariants
      ? record.SubProductVariants.subProductId
      : 0;

  const handleClick = () => {
    dataProvider
      .delete("sub-product-variants", {
        subProductId,
        variantId: record.SubProductVariants.variantId,
      } as any)
      .then(({ data }) => {
        //history.push(`/admins/${adminId}/show/roles`)
        //window.location.reload(); //because same path, just reload the page
        redirect(`/sub-products/${subProductId}/show/variants`);
        refresh();
      })
      .catch((error) => {
        notify(
          `Error deleting sub product variants with message: ${error.message}`
        );
        refresh();
      });
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      label="Delete"
    >
      <DeleteIcon />
    </Button>
  );
};

export default withStyles(styles)(DeleteSubProductVariantButton);

import React from "react";
import { Loading, useGetOne } from "react-admin";

const MediaImagePreview = ({
  id,
  imageWidth,
}: {
  id: any;
  imageWidth?: string;
}) => {
  const { data, loading, error } = useGetOne("media", id);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <img
      src={data.url}
      style={{ width: imageWidth ? imageWidth : "300px" }}
      alt={data.name}
    ></img>
  );
};

export default MediaImagePreview;

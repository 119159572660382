import React from "react";
import { Link } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddCarouselGalleryButton = ({
  classes,
  record,
}: {
  classes?: any;
  record?: any;
}) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/carousel-galleries/create?carouselId=${record.id}`}
    label="Add Carousel Gallery"
  >
    <ImageIcon />
  </Button>
);

export default withStyles(styles)(AddCarouselGalleryButton);

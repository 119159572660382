import React from "react";
import {
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  BooleanField,
  DateField,
  TopToolbar,
  ListButton,
  EditButton,
  ImageField,
} from "react-admin";
import DecimalNumberField from "../../utilities/DecimalNumberField";
import AddSubProductPriceButton from "./AddSubProductPriceButton";
import AddSubProductVariantButton from "./AddSubProductVariantButton";
import DeleteSubProductVariantButton from "./DeleteSubProductVariantButton";

const SubProductShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: string;
}) => {
  return (
    <TopToolbar>
      <ListButton
        basePath={basePath}
        record={data}
        label="Back To Sub Products"
      />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const SubProductShow = (props: any) => {
  return (
    <Show {...props} actions={<SubProductShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <ReferenceField
            label="Product"
            source="productId"
            reference="products"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="sku" />
          <TextField source="slug" />
          <TextField source="name" />
          <TextField source="displayName" />
          <TextField source="description" />
          <NumberField source="stockQuantity" />
          <BooleanField source="main" />
          <BooleanField source="active" />
          <ImageField label="Main Image" source="mainImageUrl" />
          <ImageField label="Thumbnail Image" source="thumbnailImageUrl" />
        </Tab>
        <Tab label="Pricing" path="sub-product-prices">
          <ReferenceManyField
            addLabel={false}
            reference="sub-product-prices"
            target="subProductId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <ReferenceField
                label="Currency"
                source="currencyCode"
                reference="currencies"
              >
                <TextField source="code" />
              </ReferenceField>
              <DecimalNumberField label="Price" source="price" />
              <DecimalNumberField label="Discount" source="discount" />
              <BooleanField label="Active" source="active" />
              <DateField
                label="Updated At"
                source="updatedAt"
                locales="id-ID"
                showTime
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddSubProductPriceButton />
        </Tab>
        <Tab label="Variant" path="variants">
          <ReferenceManyField
            addLabel={false}
            reference="sub-product-variants"
            target="subProductId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <TextField source="property" />
              <TextField source="value" />
              <DateField
                label="Created At"
                source="createdAt"
                locales="id-ID"
                showTime
              />
              <DateField
                label="Updated At"
                source="updatedAt"
                locales="id-ID"
                showTime
              />
              <DeleteSubProductVariantButton />
            </Datagrid>
          </ReferenceManyField>
          <AddSubProductVariantButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SubProductShow;

import UserList from "./UserList";
import UserShow from "./UserShow";
import UserIcon from "@material-ui/icons/Person";

export { UserList, UserShow };

export default {
  show: UserShow,
  list: UserList,
  icon: UserIcon,
  options: { label: "Users" },
};

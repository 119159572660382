import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em'
  }
};

const AddAdminRoleButton = ({ classes, record }) => {
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={`/admin-roles/create?adminId=${record.id}`}
      label="Add Admin Roles"
    >
      <AddIcon />
    </Button>
  )
};

export default withStyles(styles)(AddAdminRoleButton);

import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {
  fetchEnd,
  fetchStart,
  Title,
  useNotify,
  useRefresh,
  useAuthProvider,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { adminChangePassword } from "../providers/backend";

const useStyles = makeStyles({
  configuration: {
    paddingLeft: "1rem",
  },
  changePasswordApplyButton: {
    marginTop: "1rem",
    color: "white",
    background: "#94807f",
    "&:hover": {
      color: "white",
      background: "#b4a9a5",
    },
  },
  formGroup: { marginTop: "1rem" },
  customInput: {
    width: "50%",
    borderRadius: "5px",
    border: "1px solid black",
    padding: "10px",
  },
  customLabel: {
    display: "block",
    marginBottom: "0.5rem",
  },
  changePasswordForm: {},
});

const Configuration = () => {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();

  const submitChangePassword = (e: any) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      notify("New Password doesn't match", "warning");
      return;
    }

    var re = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );

    if (!re.test(newPassword)) {
      notify("New Password too simple", "warning");
      return;
    }

    setLoading(true);
    dispatch(fetchStart());

    adminChangePassword({
      oldPassword,
      newPassword,
    })
      .then(() => {
        notify(`Password successfully changed`);
        authProvider.logout({});
        redirect("/login");
      })
      .catch((e) => {
        console.error(e.response);
        const errorMessage =
          e.response.data && e.response.data.errorMessage
            ? e.response.data.errorMessage
            : "Error, please check log";

        notify(errorMessage, "warning");
        refresh();
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <Card className={classes.configuration}>
      <Title title={"Configuration"} />
      <CardContent>
        <h3>Change Password</h3>
        <p>
          <b>You will be logged out</b>
        </p>
        <p>
          At least one upper case English letter (A-Z)
          <br />
          At least one lower case English letter (a-z) <br />
          At least one digit (0-9) <br />
          At least one special character <br />
          Minimum eight in length
        </p>

        <form
          className={classes.changePasswordForm}
          onSubmit={submitChangePassword}
        >
          <div className={classes.formGroup}>
            <label htmlFor="oldPassword" className={classes.customLabel}>
              Old Password
            </label>
            <input
              type="password"
              placeholder="Old Password"
              name="oldPassword"
              className={classes.customInput}
              value={oldPassword}
              onChange={(e: any) => {
                setOldPassword(e.target.value);
              }}
            ></input>
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="newPassword" className={classes.customLabel}>
              New Password
            </label>
            <input
              type="password"
              placeholder="New Password"
              name="newPassword"
              className={classes.customInput}
              value={newPassword}
              onChange={(e: any) => {
                setNewPassword(e.target.value);
              }}
            ></input>
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="confirmNewPassword" className={classes.customLabel}>
              Confirm New Password
            </label>
            <input
              type="password"
              placeholder="Confirm New Password"
              name="confirmNewPassword"
              className={classes.customInput}
              value={confirmNewPassword}
              onChange={(e: any) => {
                setConfirmNewPassword(e.target.value);
              }}
            ></input>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.changePasswordApplyButton}
            type="submit"
            disabled={loading}
          >
            Apply
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default Configuration;

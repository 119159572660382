import React from "react";

const ImagePreview = ({ record }: { record?: any }) => {
  if (record && record.type === "IMAGE") {
    return (
      <img
        src={record.url}
        style={{
          margin: "0.5rem",
          maxHeight: "10rem",
        }}
        alt={record.name}
      />
    );
  }

  return <p>-</p>;
};

//Set label
ImagePreview.defaultProps = { label: "Preview" };

export default ImagePreview;

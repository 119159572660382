import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const CategoryCreate = (props: any) => {
  return (
    <div>
      <Create {...props}>
        <SimpleForm redirect={`/categories`}>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="description" />
          <MediaReferenceInput
            label="Icon from Media"
            source="iconMediaId"
            reference="media"
          />
          <SelectInput
            source="status"
            choices={[
              { id: "ACTIVE", name: "ACTIVE" },
              { id: "INACTIVE", name: "INACTIVE" },
            ]}
            defaultValue="ACTIVE"
          />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default CategoryCreate;

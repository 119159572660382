import React from "react";

const ShippingLabelField = ({
  record,
  display,
}: {
  record?: any;
  display?: string;
}) => {
  let baseUrl = process.env.REACT_APP_API_BASE_URL;

  let orderId = record && record.id ? record.id : "";

  return (
    <a
      rel="noopener noreferrer"
      href={`${baseUrl}/orders/shipping/label?ids=${orderId}`}
      target="_blank"
    >
      {display || "Download Shipping Label"}
    </a>
  );
};

ShippingLabelField.defaultProps = { label: "Shipping Label" };
export default ShippingLabelField;

import * as React from "react";
import { Edit, SimpleForm, TextInput, required } from "react-admin";

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" label="Role Name" validate={[required()]}/>
    </SimpleForm>
  </Edit>
);

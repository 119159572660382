import TagIcon from "@material-ui/icons/LabelImportant";
import TagCreate from "./TagCreate";
import TagEdit from "./TagEdit";
import TagList from "./TagList";
import TagShow from "./TagShow";

export default {
  create: TagCreate,
  edit: TagEdit,
  list: TagList,
  icon: TagIcon,
  show: TagShow,
  options: { label: "Tags" },
};

import CurrencyRateCreate from "./CurrencyRateCreate";
import CurrencyRateList from "./CurrencyRateList";
import CurrencyRateEdit from "./CurrencyRateEdit";
import CurrencyRateIcon from "@material-ui/icons/TrendingUp";

export default {
  create: CurrencyRateCreate,
  list: CurrencyRateList,
  edit: CurrencyRateEdit,
  icon: CurrencyRateIcon,
  options: { label: "Currency Rates" },
};

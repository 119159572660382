import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  required,
} from "react-admin";
import { parse } from "query-string";

const SubProductPriceCreate = (props) => {
  // Read the carouselId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { subProductId } = parse(props.location.search);

  const redirect = subProductId
    ? `/sub-products/${subProductId}/show/sub-product-prices`
    : false;

  return (
    <div>
      <Create {...props}>
        <SimpleForm initialValues={{ subProductId }} redirect={redirect}>
          <ReferenceInput
            disabled
            label="Sub Product"
            source="subProductId"
            reference="sub-products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            label="Currency"
            source="currencyCode"
            reference="currencies"
            validate={[required()]}
          >
            <SelectInput optionText="code" />
          </ReferenceInput>
          <NumberInput
            label="Price"
            source="price"
            defaultValue={0}
            validate={[required()]}
          />
          <NumberInput label="Discount" source="discount" defaultValue={0} />
          <BooleanInput
            label="Active"
            source="active"
            validate={[required()]}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default SubProductPriceCreate;

import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

const AdminRoleToolbar = (props) => {
  const adminId =
    props.record && props.record.adminId ? props.record.adminId : null;
  const redirectUrl = adminId ? `/admins/${adminId}/show/roles` : "/admins";

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Admin Role" redirect={redirectUrl} />
      <DeleteButton label="Delete" redirect={redirectUrl} />
    </Toolbar>
  );
};

const redirect = (basePath, id, data) => {
  return `/admins/${data.adminId}/show/roles`;
};

const AdminRoleEdit = (props) => {
  return (
    <div>
      <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<AdminRoleToolbar />}>
          <ReferenceInput
            disabled
            label="Admin"
            source="adminId"
            reference="admins"
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
          <ReferenceInput
            label="Role"
            source="roleId"
            reference="roles"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default AdminRoleEdit;

import React from "react";
import {
  Edit,
  SimpleForm,
  BooleanInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ImageField,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const ProductGalleryToolbar = (props: any) => {
  const productId =
    props.record && props.record.productId ? props.record.productId : null;
  const redirectUrl = productId
    ? `/products/${productId}/show/galleries`
    : "/products";

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Product Gallery" redirect={redirectUrl} />
      <DeleteButton label="Delete" redirect={redirectUrl} />
    </Toolbar>
  );
};

const redirect = (basePath: string, id: any, data: any) => {
  return `/products/${data.productId}/show/galleries`;
};

const ProductGalleryEdit = (props: any) => {
  return (
    <div>
      <Edit {...props}>
        <SimpleForm redirect={redirect} toolbar={<ProductGalleryToolbar />}>
          <ReferenceInput
            disabled
            label="Product"
            source="productId"
            reference="products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ImageField label="Current Main Image" source="mainImageUrl" />
          <ImageField label="Current Thumbnail Image" source="thumbnailUrl" />
          <MediaReferenceInput
            label="Main Image from Media"
            source="mainImageMediaId"
          />
          <MediaReferenceInput
            label="Thumbnail Image from Media"
            source="thumbnailMediaId"
          />
          <TextInput
            label="Alt Text"
            source="altText"
            validate={[required()]}
          />
          <NumberInput label="Position" source="position" defaultValue={0} />
          <BooleanInput label="Active" source="active" defaultValue={true} />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default ProductGalleryEdit;

import PromoCreate from "./PromoCreate";
import PromoList from "./PromoList";
import PromoIcon from "@material-ui/icons/Loyalty";
import PromoEdit from "./PromoEdit";

export default {
  create: PromoCreate,
  edit: PromoEdit,
  list: PromoList,
  icon: PromoIcon,
  options: { label: "Promo" },
};

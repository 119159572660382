import CurrencyCreate from "./CurrencyCreate";
import CurrencyEdit from "./CurrencyEdit";
import CurrencyIcon from "@material-ui/icons/LocalAtm";

export default {
  create: CurrencyCreate,
  edit: CurrencyEdit,
  icon: CurrencyIcon,
  options: { label: "Currencies" },
};

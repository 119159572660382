import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  DeleteButton,
  SaveButton,
  Toolbar,
  ImageField,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const SubCategoryToolbar = (props: any) => {
  const categoryId =
    props.record && props.record.categoryId ? props.record.categoryId : null;
  const redirectUrl = categoryId
    ? `/categories/${categoryId}/show/subcategories`
    : "/categories";

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Sub Category" redirect={redirectUrl} />
      <DeleteButton label="Delete" redirect={redirectUrl} />
    </Toolbar>
  );
};

const redirect = (basePath: string, id: string, data: any) => {
  return `/categories/${data.categoryId}/show/subcategories`;
};

const SubCategoryEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect} toolbar={<SubCategoryToolbar />}>
        <TextInput disabled source="id" />
        <TextInput disabled source="slug" />
        <ReferenceInput source="categoryId" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={[required()]} />
        <TextInput multiline source="description" />
        <ImageField label="Current Icon" source="iconUrl" />
        <MediaReferenceInput
          label="Choose Other Icon"
          source="iconMediaId"
          reference="media"
        />
        <SelectInput
          source="status"
          choices={[
            { id: "ACTIVE", name: "ACTIVE" },
            { id: "INACTIVE", name: "INACTIVE" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default SubCategoryEdit;

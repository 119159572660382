import MediaList from "./MediaList";
import MediaCreate from "./MediaCreate";
import MediaIcon from "@material-ui/icons/PermMedia";

export default {
  list: MediaList,
  create: MediaCreate,
  options: { label: "Media" },
  icon: MediaIcon,
};

import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddSubProductVariantButton = ({
  classes,
  record,
}: {
  classes: any;
  record?: any;
}) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/sub-product-variants/create?subProductId=${record.id}`}
    label="Add Sub Product Variant"
  >
    <AddIcon />
  </Button>
);

export default withStyles(styles)(AddSubProductVariantButton);

import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
};

const EditOrderShippingButton = ({
  classes,
  record,
}: {
  classes: any;
  record?: any;
}) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/order-shippings/${record.id}?orderId=${record.orderId}`}
    label="Edit"
  >
    <EditIcon />
  </Button>
);

export default withStyles(styles)(EditOrderShippingButton);

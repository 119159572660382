import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  DateInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";

const CurrencyRateEdit = (props) => {
  return (
    <Edit {...props} title={`Edit Currency Rates #${props.id}`}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <ReferenceInput
          label="Source Currency"
          source="sourceCurrency"
          reference="currencies"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
          label="Target Currency"
          source="targetCurrency"
          reference="currencies"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <NumberInput source="exchangeRate" />
        <DateInput source="validFromDate" />
        <DateInput source="validToDate" />
      </SimpleForm>
    </Edit>
  );
};

export default CurrencyRateEdit;

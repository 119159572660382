import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources, MenuProps } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import LabelIcon from "@material-ui/icons/Label";
import { FC } from "react";

const Menu: FC<MenuProps> = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      {resources.map((resource: any) => {
        if (resource.hasList) {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        }
        return null;
      })}
      <MenuItemLink
        to="/custom-route"
        primaryText="Miscellaneous"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;

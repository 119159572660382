import OrderList from "./OrderList";
import OrderIcon from "@material-ui/icons/ShoppingCart";
import OrderShow from "./OrderShow";

export default {
  list: OrderList,
  icon: OrderIcon,
  show: OrderShow,
  options: { label: "Orders" },
};

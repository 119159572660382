import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  EmailField,
  TopToolbar,
  ListButton,
  DeleteButton,
  ReferenceManyField,
  Datagrid,
} from "react-admin";

import AddAdminRoleButton from "./AddAdminRoleButton";
import DeleteAdminRoleButton from "./DeleteAdminRoleButton";

const AdminShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Back To List" />
    <DeleteButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);

const AdminShow = (props) => {
  return (
    <Show {...props} actions={<AdminShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <EmailField source="email" />
          <TextField source="phoneNumber" />
          <TextField source="status" />
          <DateField source="createdAt" locales="id-ID" />
          <DateField source="updatedAt" locales="id-ID" />
        </Tab>
        <Tab label="Roles" path="roles">
          <ReferenceManyField
            addLabel={false}
            reference="admin-roles"
            target="adminId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <TextField label="Id" source="id" />
              <TextField label="Role Name" source="name" />
              <DateField
                label="Created At"
                source="createdAt"
                localed="id-ID"
                showTime
              />
              <DateField
                label="Updated At"
                source="updatedAt"
                localed="id-ID"
                showTime
              />
              <DeleteAdminRoleButton />
            </Datagrid>
          </ReferenceManyField>
          <AddAdminRoleButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AdminShow;

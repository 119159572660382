import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { parse } from "query-string";

const ProductTagsCreate = (props: any) => {
  // Read the caroproductIduselId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { productId } = parse(props.location.search);

  const redirect = productId ? `/products/${productId}/show/tags` : false;

  return (
    <div>
      <Create title="Add Product Tags" {...props}>
        <SimpleForm initialValues={{ productId }} redirect={redirect}>
          <ReferenceInput
            disabled
            label="Product"
            source="productId"
            reference="products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceArrayInput
            label="Tags"
            source="tagIds"
            reference="tags"
            filter={{ active: true }}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default ProductTagsCreate;

import * as React from "react";
import { Card, CardHeader } from "@material-ui/core";
import { getAdminData } from "../services/dataService";

export default () => {
  const admin = getAdminData();
  const email = admin && admin.email ? admin.email : "Anonymous!";

  const title = `Welcome, ${email}!`;

  return (
    <Card>
      <CardHeader title={title} />
    </Card>
  );
};

import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const redirect = (basePath: string, id: number, data: any) => {
  return `/tags/${id}`;
};

export const TagEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" />
      <TextInput source="description" />
      <BooleanInput source="showToUser" defaultValue={true} />
      <BooleanInput source="active" defaultValue={true} />
    </SimpleForm>
  </Edit>
);

export default TagEdit;

import React from "react";
import { Admin, Resource } from "react-admin";

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";

import Dashboard from "./pages/Dashboard";
import Admins from "./resources/admins/Admins";
import Roles from "./resources/roles/Roles";
import AdminRoles from "./resources/admin-roles/AdminRoles";
import Categories from "./resources/categories/Categories";
import SubCategories from "./resources/subcategories/SubCategories";
import Currencies from "./resources/currencies/Currencies";
import CurrencyRates from "./resources/currency-rates/CurrencyRates";
import Carousels from "./resources/carousels/Carousels";
import CarouselGalleries from "./resources/carousel-galleries/CarouselGalleries";
import Products from "./resources/products/Products";
import ProductDescriptions from "./resources/product-descriptions/ProductDescriptions";
import ProductGalleries from "./resources/product-galleries/ProductGalleries";
import SubProducts from "./resources/sub-products/SubProducts";
import SubProductPrices from "./resources/sub-product-prices/SubProductPrices";
import SubProductVariants from "./resources/sub-product-variants/SubProductVariants";
import Media from "./resources/media/Media";
import Orders from "./resources/orders/Orders";
import OrderShippings from "./resources/order-shippings/OrderShippings";
import Promos from "./resources/promos/Promos";
import Layout from "./layouts/Layout";
import customRoutes from "./routes";
import "./App.css";
import Users from "./resources/users/Users";
import ProductTags from "./resources/product-tags/ProductTags";
import Tags from "./resources/tags/Tags";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
    layout={Layout}
    customRoutes={customRoutes}
  >
    <Resource name="admins" {...Admins} />
    <Resource name="admin-roles" {...AdminRoles} />
    <Resource name="roles" {...Roles} />
    <Resource name="users" {...Users} />
    <Resource name="categories" {...Categories} />
    <Resource name="subcategories" {...SubCategories} />
    <Resource name="currencies" {...Currencies} />
    <Resource name="currency-rates" {...CurrencyRates} />
    <Resource name="carousels" {...Carousels} />
    <Resource name="carousel-galleries" {...CarouselGalleries} />
    <Resource name="product-descriptions" {...ProductDescriptions} />
    <Resource name="product-galleries" {...ProductGalleries} />
    <Resource name="tags" {...Tags} />
    <Resource name="products" {...Products} />
    <Resource name="sub-products" {...SubProducts} />
    <Resource name="sub-product-prices" {...SubProductPrices} />
    <Resource name="sub-product-variants" {...SubProductVariants} />
    <Resource name="media" {...Media} />
    <Resource name="orders" {...Orders} />
    <Resource name="order-shippings" {...OrderShippings} />
    <Resource name="promos" {...Promos} />
    <Resource name="product-tags" {...ProductTags} />
  </Admin>
);

export default App;

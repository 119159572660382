import React from "react";
import { FormDataConsumer, ReferenceInput, SelectInput } from "react-admin";
import MediaImagePreview from "./MediaImagePreview";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

const MediaReferenceInput = (props: any) => {
  const classes = useStyles();

  let isArrayInput = props && props.isArrayInput ? true : false;
  let newProps = { ...props };
  delete newProps.isArrayInput;

  return (
    <div className={classes.root}>
      <ReferenceInput {...newProps} reference="media">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData[newProps.source] &&
            !isArrayInput && (
              <MediaImagePreview id={formData[newProps.source]} />
            )
          );
        }}
      </FormDataConsumer>
    </div>
  );
};

export default MediaReferenceInput;

import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { parse } from "query-string";

const AdminRoleCreate = (props) => {
  // Read the carouselId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { adminId } = parse(props.location.search);

  const redirect = adminId ? `/admins/${adminId}/show/roles` : false;

  return (
    <div>
      <Create {...props}>
        <SimpleForm initialValues={{ adminId }} redirect={redirect}>
          <ReferenceInput
            disabled
            label="Admin"
            source="adminId"
            reference="admins"
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
          <ReferenceInput
            label="Role"
            source="roleId"
            reference="roles"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default AdminRoleCreate;

import * as React from "react";
import { forwardRef } from "react";
import { AppBar, UserMenu, MenuItemLink } from "react-admin";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    flex: 1,
  },
  spacer: {
    flex: 1,
  },
  background: {
    background: "#94807f",
    padding: "0.1rem",
  },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={"Configuration"}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  let customProps = { ...props };

  // Fix console error React does not recognize the `staticContext` prop on a DOM element
  delete customProps.staticContext;
  delete customProps.staticcontext;

  return (
    <AppBar
      {...customProps}
      elevation={1}
      userMenu={<CustomUserMenu />}
      className={classes.background}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img
        src="https://titianjewelry.s3-ap-southeast-1.amazonaws.com/images/logo-new-titian-white-ls.png"
        alt="Titian Jewelry"
        width={100}
        height={40}
        style={{
          cursor: "pointer",
        }}
        onClick={() => props.history.push("/")}
      ></img>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default withRouter(CustomAppBar);

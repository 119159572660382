import ProductList from "./ProductList";
import ProductIcon from "@material-ui/icons/Storefront";
import ProductCreate from "./ProductCreate";
import ProductShow from "./ProductShow";
import ProductEdit from "./ProductEdit";

export default {
  list: ProductList,
  icon: ProductIcon,
  create: ProductCreate,
  show: ProductShow,
  edit: ProductEdit,
  options: { label: "Products" },
};

import SubProductCreate from "./SubProductCreate";
import SubProductEdit from "./SubProductEdit";
import SubProductList from "./SubProductList";
import SubProductShow from "./SubProductShow";

export default {
  create: SubProductCreate,
  edit: SubProductEdit,
  show: SubProductShow,
  list: SubProductList,
  options: { label: "Sub Products" },
};

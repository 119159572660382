import RoleCreate from "./RoleCreate";
import RoleEdit from "./RoleEdit";
import RoleIcon from "@material-ui/icons/Accessibility";

export default {
  create: RoleCreate,
  edit: RoleEdit,
  icon: RoleIcon,
  options: { label: "Roles" },
};

import CategoryList from "./CategoryList";
import CategoryCreate from "./CategoryCreate";
import CategoryEdit from "./CategoryEdit";
import CategoryIcon from "@material-ui/icons/Category";
import CategoryShow from "./CategoryShow";

export default {
  create: CategoryCreate,
  list: CategoryList,
  edit: CategoryEdit,
  icon: CategoryIcon,
  show: CategoryShow,
  options: { label: "Categories" },
};

import * as React from "react";
import { Layout, LayoutProps, Sidebar } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const MyLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} />
);

export default MyLayout;

import React from "react";
import {
  Edit,
  SimpleForm,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

const SubProductPriceToolbar = (props) => {
  const subProductId =
    props.record && props.record.subProductId
      ? props.record.subProductId
      : null;

  const redirectUrl = subProductId
    ? `/sub-products/${subProductId}/show/sub-product-prices`
    : "/sub-products";

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Sub Product Price" redirect={redirectUrl} />
      <DeleteButton label="Delete" redirect={redirectUrl} />
    </Toolbar>
  );
};

const SubProductPriceEdit = (props) => {
  return (
    <div>
      <Edit {...props}>
        <SimpleForm toolbar={<SubProductPriceToolbar />}>
          <ReferenceInput
            label="Currency"
            source="currencyCode"
            reference="currencies"
          >
            <SelectInput optionText="code" validate={[required()]} />
          </ReferenceInput>
          <NumberInput
            label="Price"
            source="price"
            defaultValue={0}
            validate={[required()]}
          />
          <NumberInput label="Discount" source="discount" defaultValue={0} />
          <BooleanInput
            label="Active"
            source="active"
            validate={[required()]}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default SubProductPriceEdit;

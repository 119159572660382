import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  useDataProvider,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
// import { useHistory } from 'react-router-dom';

const styles = {
  button: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: "red",
  },
};

const DeleteAdminRoleButton = ({ classes, record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const adminId = record && record.AdminRoles ? record.AdminRoles.adminId : 0;

  const handleClick = () => {
    dataProvider
      .delete("admin-roles", {
        adminId,
        roleId: record.AdminRoles.roleId,
      })
      .then(({ data }) => {
        //history.push(`/admins/${adminId}/show/roles`)
        //window.location.reload(); //because same path, just reload the page
        redirect(`/admins/${adminId}/show/roles`);
        refresh();
      })
      .catch((error) => {
        notify(`Error deleting admin role with message: ${error.message}`);
        refresh();
      });
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      label="Delete"
    >
      <DeleteIcon />
    </Button>
  );
};

export default withStyles(styles)(DeleteAdminRoleButton);

import * as React from "react";
import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";

const redirect = () => {
  return `/tags`;
};

export const TagCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" />
      <TextInput source="description" />
      <BooleanInput source="showToUser" defaultValue={true} />
      <BooleanInput source="active" defaultValue={true} />
    </SimpleForm>
  </Create>
);

export default TagCreate;

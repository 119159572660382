import AdminList from "./AdminList";
import AdminCreate from "./AdminCreate";
import AdminShow from "./AdminShow";
import AdminIcon from "@material-ui/icons/SupervisorAccount";

export { AdminCreate, AdminList, AdminShow };

export default {
  show: AdminShow,
  create: AdminCreate,
  list: AdminList,
  icon: AdminIcon,
  options: { label: "Admins" },
};

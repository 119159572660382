import * as React from "react";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  BulkDeleteButton,
  Datagrid,
  DatagridProps,
  DateField,
  Filter,
  FilterProps,
  Identifier,
  List,
  ListContextProvider,
  ListProps,
  ShowButton,
  TextField,
  TextInput,
  useGetList,
  useListContext,
} from "react-admin";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DecimalNumberField from "../../utilities/DecimalNumberField";
import {
  PaymentConfirmationImage,
  PaymentConfirmationTrfTo,
} from "./PaymentConfirmationUtils";
import ShippingLabelDownload from "./ShippingLabelDownload";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface TabbedDatagridProps extends DatagridProps {}

const OrderFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <TextInput label="Order ID" source="publicOrderId" alwaysOn />
    <TextInput label="Grand Total >" source="grandTotal_gte" />
  </Filter>
);

const useDatagridStyles = makeStyles({
  total: { fontWeight: "bold" },
});

const tabs = [
  { id: "PENDING_CONFIRMATION", name: "Pending Confirmation" },
  { id: "PROCESSING", name: "Processing" },
  { id: "SHIPPING", name: "SHIPPING" },
  { id: "ARRIVED", name: "ARRIVED" },
  { id: "ALL", name: "ALL" },
];

const useGetTotals = (filterValues: any) => {
  const { total: totalPendingConfirmation } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "pendingConfirmationDate", order: "ASC" },
    { ...filterValues, status: "PENDING_CONFIRMATION" }
  );
  const { total: totalProcessing } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "processingDate", order: "ASC" },
    { ...filterValues, status: "PROCESSING" }
  );
  const { total: totalShipping } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "shippingDate", order: "ASC" },
    { ...filterValues, status: "SHIPPING" }
  );
  const { total: totalArrived } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "arrivedDate", order: "ASC" },
    { ...filterValues, status: "ARRIVED" }
  );

  // HACKED FROM BACKEND, if ALL then remove status filter
  const { total: totalAll } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "id", order: "DESC" },
    { ...filterValues, status: "ALL" }
  );

  return {
    pendingConfirmation: totalPendingConfirmation,
    processing: totalProcessing,
    shipping: totalShipping,
    arrived: totalArrived,
    all: totalAll,
  };
};

const AWBField = ({ record }: { record?: any }) => {
  if (record && record.orderShippings && record.orderShippings.length > 0) {
    const toBuyerShipping = record.orderShippings.find(
      (e: any) => e.direction === "TO_BUYER"
    );

    if (toBuyerShipping) {
      return <span>{toBuyerShipping.awb}</span>;
    }
  }
  return <span>-</span>;
};

AWBField.defaultProps = { label: "AWB" };

const TabbedDatagrid: FC<TabbedDatagridProps> = (props) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const classes = useDatagridStyles();
  const [pendingConfirmation, setPendingConfirmation] = useState<Identifier[]>(
    [] as Identifier[]
  );
  const [processing, setProcessing] = useState<Identifier[]>(
    [] as Identifier[]
  );
  const [shipping, setShipping] = useState<Identifier[]>([] as Identifier[]);
  const [all, setAll] = useState<Identifier[]>([] as Identifier[]);
  const [arrived, setArrived] = useState<Identifier[]>([] as Identifier[]);
  const totals = useGetTotals(filterValues) as any;

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case "PENDING_CONFIRMATION":
          setPendingConfirmation(ids);
          break;
        case "PROCESSING":
          setProcessing(ids);
          break;
        case "SHIPPING":
          setShipping(ids);
          break;
        case "ALL":
          setAll(ids);
          break;
        case "ARRIVED":
          setArrived(ids);
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        {filterValues.status === "PENDING_CONFIRMATION" && (
          <ListContextProvider
            value={{ ...listContext, ids: pendingConfirmation }}
          >
            <Datagrid {...props} optimized rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField
                label="Pending Confirmation Date"
                source="pendingConfirmationDate"
                showTime
              />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <TextField label="Payment Method" source="paymentMethod" />
              <PaymentConfirmationTrfTo />
              <PaymentConfirmationImage />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === "PROCESSING" && (
          <ListContextProvider value={{ ...listContext, ids: processing }}>
            <Datagrid {...props} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField
                label="Processing Date"
                source="processingDate"
                showTime
              />
              <TextField source="recipientName" />
              <TextField source="country" />
              <TextField source="province" />
              <TextField source="city" />
              <TextField source="subdistrict" />
              <TextField source="postalCode" />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShippingLabelDownload display="Download" />
              <ShowButton />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === "SHIPPING" && (
          <ListContextProvider value={{ ...listContext, ids: shipping }}>
            <Datagrid {...props} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField label="Shipping Date" source="shippingDate" showTime />
              <TextField source="recipientName" />
              <TextField source="country" />
              <TextField source="province" />
              <TextField source="city" />
              <TextField source="subdistrict" />
              <TextField source="postalCode" />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <AWBField />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === "ARRIVED" && (
          <ListContextProvider value={{ ...listContext, ids: arrived }}>
            <Datagrid {...props} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField label="Arrived Date" source="arrivedDate" showTime />
              <TextField source="recipientName" />
              <TextField source="country" />
              <TextField source="province" />
              <TextField source="city" />
              <TextField source="subdistrict" />
              <TextField source="postalCode" />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <AWBField />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === "ALL" && (
          <ListContextProvider value={{ ...listContext, ids: all }}>
            <Datagrid {...props} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <TextField label="Status" source="status" />
              <AWBField />
              <TextField label="Recipient" source="recipientName" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <DateField label="Init Date" source="initDate" showTime />
              <DateField label="Shipping Date" source="shippingDate" showTime />
              <DateField label="Arrived Date" source="arrivedDate" showTime />
              <DateField label="Success Date" source="successDate" showTime />
              <DateField label="Failed Date" source="failedDate" showTime />
              <TextField label="Total Items" source="orderItems.length" />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          </ListContextProvider>
        )}
      </div>
    </Fragment>
  );
};

const OrderBulkActionButtons = (props: any) => {
  return (
    <Fragment>
      <Button
        style={{ marginBottom: "1rem" }}
        variant="contained"
        color="primary"
        onClick={() => {
          window.open(
            `${API_BASE_URL}/orders/shipping/label?ids=${props.selectedIds.join(
              ","
            )}`,
            "_blank"
          );
        }}
      >
        Download Label
      </Button>
      {/* <BulkDeleteButton {...props} /> */}
    </Fragment>
  );
};

const OrderList: FC<ListProps> = (props) => (
  <List
    {...props}
    filterDefaultValues={{ status: "PENDING_CONFIRMATION" }}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={25}
    filters={<OrderFilter />}
    bulkActionButtons={<OrderBulkActionButtons />}
  >
    <TabbedDatagrid />
  </List>
);

export default OrderList;

import React from "react";
import {
  Create,
  NumberInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  required,
} from "react-admin";

const CurrencyRateCreate = (props) => {
  return (
    <Create {...props} title="Create Currency Rates">
      <SimpleForm>
        <ReferenceInput
          label="Source Currency"
          source="sourceCurrency"
          reference="currencies"
          validate={[required()]}
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
          label="Target Currency"
          source="targetCurrency"
          reference="currencies"
          validate={[required()]}
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <NumberInput source="exchangeRate" validate={[required()]} />
        <DateInput source="validFromDate" validate={[required()]} />
        <DateInput source="validToDate" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

export default CurrencyRateCreate;

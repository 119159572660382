import React from "react";
import {
  Datagrid,
  ReferenceManyField,
  Show,
  EditButton,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  BooleanField,
  ImageField,
  UrlField,
  ShowProps,
} from "react-admin";
import AddCarouselGalleryButton from "./AddCarouselGalleryButton";

const CarouselShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="mainCarousel" />
        </Tab>
        <Tab label="Carousel Galleries" path="galleries">
          <ReferenceManyField
            addLabel={false}
            reference="carousel-galleries"
            target="carouselId"
            sort={{ field: "position", order: "ASC" }}
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="description" />
              <UrlField source="onClickUrl" />
              <TextField source="onHoverText" />
              <TextField source="alText" />
              <ImageField source="desktopImageUrl" />
              <ImageField source="mobileImageUrl" />
              <NumberField source="position" />
              <BooleanField source="active" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddCarouselGalleryButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CarouselShow;

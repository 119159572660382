import moment from "moment";
const baseUrl =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8888/api-admin";
const loginUrl = `${baseUrl}/admins/login`;

export default {
  login: ({ username, password }) => {
    const request = new Request(loginUrl, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, admin }) => {
        const now = moment();
        const roles = [];

        if (admin.roles) {
          for (const role of admin.roles) {
            roles.push(role.name);
          }
        }

        localStorage.setItem("token", token);
        localStorage.setItem("tokenExpiry", now.endOf("day").valueOf());
        localStorage.setItem("admin", JSON.stringify(admin));
        localStorage.setItem("roles", roles);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("admin");
    localStorage.removeItem("roles");

    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const tokenExists = localStorage.getItem("token");
    const tokenExpiry = localStorage.getItem("tokenExpiry");

    if (!tokenExpiry) {
      return Promise.reject();
    }

    const isTokenExpired = moment().isAfter(parseInt(tokenExpiry));

    return !tokenExists || isTokenExpired
      ? Promise.reject()
      : Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem("roles");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  EditButton,
} from "react-admin";

const CurrencyRateList = (props: any) => {
  return (
    <List
      {...props}
      title="Currency Rates"
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          label="Source Currency"
          source="sourceCurrency"
          reference="currencies"
        >
          <TextField source="code" />
        </ReferenceField>
        <ReferenceField
          label="Target Currency"
          source="targetCurrency"
          reference="currencies"
        >
          <TextField source="code" />
        </ReferenceField>
        <TextField source="exchangeRate" />
        <DateField source="validFromDate" showTime locales="id-ID" />
        <DateField source="validToDate" showTime locales="id-ID" />
        <DateField source="createdAt" locales="id-ID" />
        <DateField source="updatedAt" locales="id-ID" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CurrencyRateList;

import React from "react";
import { Link } from "react-router-dom";
import ClassIcon from "@material-ui/icons/Class";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddSubCategoryButton = ({
  classes,
  record,
}: {
  classes?: any;
  record?: any;
}) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/subcategories/create?categoryId=${record.id}`}
    label="Add Sub Category"
  >
    <ClassIcon />
  </Button>
);

export default withStyles(styles)(AddSubCategoryButton);

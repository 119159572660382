import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  TextInput,
  SelectInput,
  required,
  NumberInput,
} from "react-admin";
import { parse } from "query-string";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const ProductGalleryCreate = (props: any) => {
  // Read the carouselId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { productId } = parse(props.location.search);

  const redirect = productId ? `/products/${productId}/show/galleries` : false;

  return (
    <div>
      <Create {...props}>
        <SimpleForm initialValues={{ productId }} redirect={redirect}>
          <ReferenceInput
            disabled
            label="Product"
            source="productId"
            reference="products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <MediaReferenceInput
            label="Main Image from Media"
            source="mainImageMediaId"
          />
          <MediaReferenceInput
            label="Thumbnail Image from Media"
            source="thumbnailMediaId"
          />
          <TextInput
            label="Alt Text"
            source="altText"
            validate={[required()]}
          />
          <NumberInput label="Position" source="position" defaultValue={0} />
          <BooleanInput label="Active" source="active" defaultValue={true} />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default ProductGalleryCreate;
